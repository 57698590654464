import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import memoize from "utils/memoize";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import useBrand from "hooks-use-brand/useBrand";

export const RHZoomLenseOutIcon: FC<SvgIconProps> = props => {
  const brandCode = useBrand();
  const style = {
    fontSize: 25,
    ...props.style
  };

  return (
    <SvgIcon viewBox="0 0 25 25" {...props} style={style}>
      <title>Grommet/ZoomInLens</title>
      <desc>Created with Sketch.</desc>
      <g id="PDP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M6.54932 9.70508H13.3793"
          stroke="black"
          stroke-width="0.597625"
        />
      </g>
      <ellipse
        cx="9.59183"
        cy="9.40615"
        rx="8.33646"
        ry="8.40615"
        stroke="black"
        stroke-width="0.85375"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4683 15.3652L21.7419 21.4899L15.4683 15.3652Z"
        fill="white"
      />
      <path
        d="M15.4683 15.3652L21.7419 21.4899"
        stroke="black"
        stroke-width="0.85375"
      />
      <defs>
        <clipPath id="clip0_16926_7337">
          <rect
            width="13.66"
            height="13.66"
            fill="white"
            transform="translate(2.9624 2.70703)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default memoize(RHZoomLenseOutIcon);
