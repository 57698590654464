import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import getCountryFromUrl from "utils/getCountryFromUrl";

const countryFromUrl = getCountryFromUrl();
export const RHInfoIcon: FC<SvgIconProps> = props => {
  const style = {
    fontSize: 20,
    ...props.style
  };

  return (
    <SvgIcon viewBox="0 0 20 20" {...props} style={style}>
      {countryFromUrl === "US" || countryFromUrl === "CA" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={12}
          height={12}
          fill="none"
          {...props}
        >
          <g clipPath="url(#a)">
            <circle cx={6} cy={6} r={5.625} stroke="#000" strokeWidth={0.75} />
            <path
              fill="#000"
              d="M5.562 3.8v-.94h.88v.94h-.88Zm.03 6.2V5h.82v5h-.82Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h12v12H0z" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <g
          id="StyleGuide-+-Components"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="STYLES" transform="translate(-2297.000000, -849.000000)">
            <g id="Group" transform="translate(2134.000000, 105.000000)">
              <g
                id="Grommet/Info"
                transform="translate(163.000000, 744.000000)"
              >
                <circle
                  id="Oval"
                  stroke="currentColor"
                  strokeWidth="1"
                  cx="10"
                  cy="10"
                  r="9.5"
                ></circle>
                <text
                  id="i"
                  fontFamily="CaslonRH-ThinItalic, Caslon RH"
                  fontSize="15"
                  fontStyle="italic"
                  fontWeight="300"
                  fill="currentColor"
                >
                  <tspan x="7.535" y="15">
                    i
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      )}
    </SvgIcon>
  );
};

export default RHInfoIcon;
