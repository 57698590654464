import React, { FC } from "react";

import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const PlayIconV2: FC<SvgIconProps> = props => {
  const style = {
    ...props.style
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || "19"}
      height={props?.height || "22"}
      viewBox="0 0 19 22"
      fill="none"
      style={style}
      {...props}
    >
      <path
        d="M3.10785 0.872085L3.10785 0.872087L3.10932 0.872988L16.6775 9.16468C16.6778 9.16483 16.678 9.16499 16.6783 9.16514C17.1664 9.46584 17.4598 9.98814 17.4598 10.5571C17.4598 11.1284 17.1645 11.6532 16.6803 11.9478L16.6792 11.9485L3.1105 20.2405C3.11024 20.2406 3.10997 20.2408 3.1097 20.2409C2.606 20.5469 1.97459 20.5589 1.46508 20.2721C0.952392 19.9835 0.633374 19.4403 0.633374 18.8498V2.26437C0.633374 1.67389 0.952393 1.13067 1.46508 0.842071C1.97725 0.553759 2.60768 0.564547 3.10785 0.872085Z"
        stroke={props?.stroke || "white"}
        stroke-width="1.26675"
      />
    </svg>
  );
};

export default PlayIconV2;
