import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const RHAddIcon: FC<SvgIconProps> = props => {
  const style = {
    fontSize: 9,
    ...props.style
  };

  return (
    <SvgIcon viewBox="0 0 4 7" {...props} style={style}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Grommet/ArrowSM/Thick-Black" fill="currentColor">
          <path
            d="M2.30389222,5.372 L5.3742515,2.25733333 C5.54191617,2.076 5.54191617,1.79866667 5.3742515,1.628 C5.20658683,1.45733333 4.92365269,1.45733333 4.75598802,1.628 L2,4.43333333 L-0.755988024,1.628 C-0.934131737,1.45733333 -1.20658683,1.45733333 -1.3742515,1.628 C-1.54191617,1.79866667 -1.54191617,2.076 -1.3742515,2.25733333 L1.68562874,5.372 C1.86377246,5.54266667 2.13622754,5.54266667 2.30389222,5.372 Z"
            id="Path"
            transform="translate(2.000000, 3.500000) rotate(-90.000000) translate(-2.000000, -3.500000) "
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
};

export default RHAddIcon;
