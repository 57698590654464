import React, { FC } from "react";

import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const IncrementIcon: FC<SvgIconProps> = props => {
  const { width = 9, height = 10 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 9 10"
      fill="none"
      {...props}
    >
      <path d="M4.5 0.5V9.5" stroke={props.stroke ?? "black"} />
      <path d="M0 5L9 5" stroke={props.stroke ?? "black"} />
    </svg>
  );
};

export default IncrementIcon;
