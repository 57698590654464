import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import yn from "yn";
import { useEnv } from "hooks/useEnv";

interface RHIconProps extends SvgIconProps {
  toggle?: boolean;
}
const env = useEnv();

const FEATURE_CART_REFINEMENT_V2 = yn(env?.FEATURE_CART_REFINEMENT_V2);
export const RHAddIcon: FC<RHIconProps> = props => {
  const { toggle = true, style: propsStyle } = props;
  const style = {
    fontSize: FEATURE_CART_REFINEMENT_V2 ? 11 : 15,
    ...propsStyle
  };
  const env = useEnv();

  return (
    <SvgIcon viewBox="0 0 15 15" {...props} style={style}>
      <title>Show</title>
      <desc>Created with Sketch.</desc>
      {FEATURE_CART_REFINEMENT_V2 && toggle ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
        >
          <path d="M5.48709 0.914062V11.9141" stroke="#201F1F" />
          <path d="M0 6.41406H10.9739" stroke="#201F1F" />
        </svg>
      ) : (
        <g
          id="StyleGuide-+-Components"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="STYLES"
            transform="translate(-2302.000000, -448.000000)"
            stroke="currentColor"
          >
            <g id="Group" transform="translate(2134.000000, 105.000000)">
              <g
                id="Grommet/PlusSign"
                transform="translate(168.000000, 343.000000)"
              >
                <g id="Group-2">
                  <line x1="7.5" y1="0" x2="7.5" y2="15" id="Path-8"></line>
                  <line x1="0" y1="7.5" x2="15" y2="7.5" id="Path-9"></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      )}
    </SvgIcon>
  );
};

export default RHAddIcon;
