import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import useBrand from "hooks-use-brand/useBrand";

export const RHZoomOutIcon: FC<SvgIconProps> = props => {
  const brandCode = useBrand();
  const style = {
    fontSize: 25,
    ...props.style
  };

  return (
    <SvgIcon viewBox="0 0 25 25" {...props} style={style}>
      <title>Grommet/ZoomOut</title>
      <desc>Created with Sketch.</desc>
      <g id="PDP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Grommet/ZoomOut">
          <g id="Group-2">
            <path
              d="M25,0 L25,25 L0,25 L0,0 L25,0 Z M19,12 L6,12 L6,13 L19,13 L19,12 Z"
              id="Combined-Shape"
              fill={brandCode === "MO" ? "#E6E6E6" : "#38352F"}
              opacity={brandCode === "MO" ? "0.5" : "0.7"}
            ></path>
            <line
              x1="6"
              y1="12.5"
              x2="19"
              y2="12.5"
              id="Path-31"
              stroke={brandCode === "MO" ? "#000000" : "#FFFFFF"}
              opacity={brandCode === "MO" ? "0.7" : "0.4"}
            ></line>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default RHZoomOutIcon;
