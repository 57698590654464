import React, { FC } from "react";

import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const DecrementIcon: FC<SvgIconProps> = props => {
  const style = {
    ...props.style
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height={props.height ?? "2"}
      viewBox="0 0 9 2"
      fill="none"
    >
      <path d="M0 1L9 1" stroke={props.stroke ?? "black"} />
    </svg>
  );
};

export default DecrementIcon;
