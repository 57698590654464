import React, { FC } from "react";
import { SvgIcon, makeStyles, createStyles } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import classNames from "classnames";
import memoize from "utils/memoize";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fill: "none",
      width: 8,
      height: 16
    },
    sm: {
      width: 8,
      height: 16
    },
    md: {
      width: 12,
      height: 24
    }
  })
);

export type RHRCarouseLeftArrowIconProps = SvgIconProps & {
  strokeColor?: string;
  size?: "sm" | "md";
};

const RHRCarouselLeftArrowIcon: FC<RHRCarouseLeftArrowIconProps> = ({
  size = "sm",
  ...props
}) => {
  const classes = useStyles();
  const style = {
    fill: "none",
    ...props.style
  };

  return (
    <SvgIcon
      viewBox="0 0 11 18"
      {...props}
      className={classNames(classes.root, classes?.[size], props.className)}
      style={style}
    >
      <path d="M10 17 2 9l8-8" stroke="currentColor" strokeWidth={1.5} />
    </SvgIcon>
  );
};

export default memoize(RHRCarouselLeftArrowIcon);
