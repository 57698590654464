import React, { FC } from "react";

export const RHCollapseIconV2: FC<{ color?: string }> = ({
  color = "black"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path d="M2 8.67969H14" stroke={color} stroke-width="0.7" />
    </svg>
  );
};

export default RHCollapseIconV2;
